import React from 'react';
import { navigate } from 'gatsby';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { airtableConfig } from '../utils/airtable';
import Airtable from 'airtable';
import dayjs from 'dayjs';

import css from '../css/components/OrderForm.module.css';
import cssButton from '../css/components/Button.module.css';

// const airtableWriteEndpoint = `https://api.airtable.com/v0/${airtableConfig.base}/${airtableConfig.inputTable}?api_key=${airtableConfig.key}`;

var base = new Airtable({ apiKey: airtableConfig.key }).base(airtableConfig.base);

const countries = countryList().getData();

export default class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.OrderForm = React.createRef();
    this.countryOptions = countries;

    this.state = {
      countryOptions: this.countryOptions,
      language: this.props.lang,
      name: '',
      email: '',
      phone: '',
      instagram: '',
      street: '',
      city: '',
      country: this.countryOptions[59],
      zip: '',
      message: '',
      earrings: '',
      necklaces: '',
      rings: '',
      other: ''
    };
  }

  selectCountry = val => {
    this.setState({ country: val });
  };

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = this.OrderForm.current;

    base('Objednávky').create(
      {
        jméno: this.state.name,
        náušnice: parseInt(this.state.earrings),
        náhrdelník: parseInt(this.state.necklaces),
        prsten: parseInt(this.state.rings),
        jiné: this.state.other,
        email: this.state.email,
        instagram: this.state.instagram,
        telefon: this.state.phone,
        ulice: this.state.street,
        město: this.state.city,
        PSČ: this.state.zip,
        země: this.state.country.label,
        stav: 'objednáno',
        'zpráva od zákazníka': this.state.message,
        datum: dayjs().format('MM/DD/YYYY')
      },
      function(err, record) {
        if (err) {
          console.error(err);
          return;
        }
        console.log('order sent');
        navigate(form.getAttribute('action'));
      }
    );
  };

  render() {
    var name = 'Full name';
    var email = 'Email';
    var instagram = 'Instagram';
    var instagramPrompt = 'If we messaged on Instagram please remind me of your username';
    var phone = 'Phone';
    var street = 'Street';
    var zip = 'ZIP';
    var city = 'City';
    var country = 'Country';
    var message = 'Note';
    var send = 'Send order';

    var contactTitle = 'Contact details';
    var addressTitle = 'Delivery address';
    var productTitle = 'What are you ordering?';
    var productReminder = 'Please remind me what products we talked about.';

    var earrings = 'earrings';
    var necklaces = 'necklace';
    var rings = 'ring';
    var other = 'other';

    if (this.state.language === 'cs') {
      name = 'Jméno a příjmení';
      email = 'Email';
      phone = 'Telefon';
      street = 'Ulice';
      zip = 'PSČ';
      city = 'Město';
      country = 'Země';
      message = 'Poznámka';
      send = 'Poslat objednávku';

      instagramPrompt =
        'Pokud jsme se domlouvali na Instagramu, tak mi prosím připomeňte vaše uživatelské jméno';

      contactTitle = 'Kontaktní údaje';
      productTitle = 'Co si objednáváte?';
      productReminder = 'Připomeňte mi prosím na jakých produktech jsme se domluvili';
      addressTitle = 'Doručovací adresa';

      earrings = 'náušnice';
      necklaces = 'náhrdelník';
      rings = 'prsten';
      other = 'jiné';
    }

    return (
      <form
        className={css.form}
        method="POST"
        name="order"
        action="/order-success/"
        onSubmit={this.handleSubmit}
        ref={this.OrderForm}
      >
        <h2>{contactTitle}</h2>
        <div className={css.group}>
          <div className={`${css.field} ${css.span6}`}>
            <label htmlFor="name">{name}</label>
            <input type="text" name="name" id="name" onChange={this.handleChange} required />
          </div>
        </div>

        <div className={css.group}>
          <div className={`${css.field} ${css.span3}`}>
            <label htmlFor="email">{email}</label>
            <input type="email" name="email" id="email" onChange={this.handleChange} required />
          </div>
          <div className={`${css.field} ${css.span3}`}>
            <label htmlFor="phone">{phone}</label>
            <input type="tel" name="phone" id="phone" onChange={this.handleChange} required />
          </div>
        </div>

        <div className={css.group}>
          <div className={css.span6}>
            <p className="small mb-0">{instagramPrompt}</p>
          </div>
          <div className={`${css.field} ${css.span3}`}>
            <label htmlFor="instagram">{instagram}</label>
            <input type="text" name="instagram" id="instagram" onChange={this.handleChange} />
          </div>
        </div>

        <hr />

        <h2>{addressTitle}</h2>
        <div className={css.group}>
          <div className={`${css.field} ${css.span6}`}>
            <label htmlFor="street">{street}</label>
            <input type="text" name="street" id="street" onChange={this.handleChange} required />
          </div>
        </div>

        <div className={css.group}>
          <div className={`${css.field} ${css.span4}`}>
            <label htmlFor="city">{city}</label>
            <input type="text" name="city" id="city" onChange={this.handleChange} required />
          </div>
          <div className={`${css.field} ${css.span2}`}>
            <label htmlFor="zip">{zip}</label>
            <input type="text" name="zip" id="zip" onChange={this.handleChange} required />
          </div>
        </div>

        <div className={css.group}>
          <div className={`${css.field} ${css.span6}`}>
            <label htmlFor="country">{country}</label>
            <Select
              options={this.state.countryOptions}
              value={this.state.country}
              onChange={this.selectCountry}
              defaultValue="CZ"
            />
          </div>
        </div>

        <hr />

        <h2>{productTitle}</h2>
        <p>{productReminder}</p>

        <div className={css.group2}>
          <div className={`${css.field} ${css.span2} ${css.quantity}`}>
            <label htmlFor="earrings">
              {' '}
              <span>x</span> {earrings}
            </label>
            <input type="number" name="earrings" id="earrings" onChange={this.handleChange} />
          </div>
          <div className={`${css.field} ${css.span2} ${css.quantity}`}>
            <label htmlFor="necklaces">
              {' '}
              <span>x</span> {necklaces}
            </label>
            <input type="number" name="necklaces" id="necklaces" onChange={this.handleChange} />
          </div>
          <div className={`${css.field} ${css.span2} ${css.quantity}`}>
            <label htmlFor="rings">
              {' '}
              <span>x</span> {rings}
            </label>
            <input type="number" name="rings" id="rings" onChange={this.handleChange} />
          </div>
        </div>

        <div className={css.group}>
          <div className={`${css.field} ${css.span6}`}>
            <label htmlFor="other">{other}</label>
            <input type="text" name="other" id="other" onChange={this.handleChange} />
          </div>
        </div>

        <hr />

        <div className={css.group}>
          <div className={`${css.field} ${css.span6}`}>
            <label htmlFor="message">{message}</label>
            <textarea name="message" id="message" rows="4" onChange={this.handleChange} />
          </div>
        </div>

        <div className={css.group}>
          <div className={`${css.field} ${css.span6} ta-center`}>
            <button className={cssButton.btn} type="submit">
              {send}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
