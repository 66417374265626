import React from 'react';

// components
import Container from '../components/Container';
import OrderForm from '../components/OrderForm';

// css
import css from '../css/pages/order.module.css';

export default ({ data, location }) => {
  return (
    <Container title="Order" permalink={location.pathname}>
      <section>
        <div className="wrap-s">
          <div className="ta-center">
            Please fill out all the details for easy communication and quick delivery
          </div>
          <div className="m-2">
            <OrderForm lang="en" />
          </div>
        </div>
      </section>
    </Container>
  );
};
